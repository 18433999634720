/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"

export const Contact = () => {
  return (
    <div className="contact-section">
      <p>ブログを見ていただき、弊社にご興味を持ってくださった方は、ぜひ弊社Webサイトもご覧ください。お仕事のご相談も可能です。</p>
      <div className="contant-button-box">
        <a href="https://kasuga-inc.co.jp/" className="contact-button">株式会社KASUGA 公式サイトへ</a>
      </div>
    </div>
  )
}
